import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[AbsenteeOption]';

export const absenteeOptionActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  DELETE: `${ACTION_PREFIX} Delete`,
  DELETE_SUCCESS: `${ACTION_PREFIX} Delete Success`,
  DELETE_FAILED: `${ACTION_PREFIX} Delete Failed`,
};

export class AbsenteeOptionAction {
  static load(): Action {
    return {
      type: absenteeOptionActionType.LOAD,
    };
  }

  static loadSuccess(options): Action {
    return {
      type: absenteeOptionActionType.LOAD_SUCCESS,
      payload: options,
    };
  }

  static loadFailed(err): Action {
    return {
      type: absenteeOptionActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(data): Action {
    return {
      type: absenteeOptionActionType.ADD,
      payload: data,
    };
  }

  static addSuccess(locationResponse): Action {
    return {
      type: absenteeOptionActionType.ADD_SUCCESS,
      payload: locationResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: absenteeOptionActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(data): Action {
    return {
      type: absenteeOptionActionType.UPDATE,
      payload: data,
    };
  }

  static updateSuccess(response): Action {
    return {
      type: absenteeOptionActionType.UPDATE_SUCCESS,
      payload: response,
    };
  }

  static updateFailed(err): Action {
    return {
      type: absenteeOptionActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  static delete(id): Action {
    return {
      type: absenteeOptionActionType.DELETE,
      payload: id,
    };
  }

  static deleteSuccess(id): Action {
    return {
      type: absenteeOptionActionType.DELETE_SUCCESS,
      payload: id,
    };
  }

  static deleteFailed(id): Action {
    return {
      type: absenteeOptionActionType.DELETE_FAILED,
      payload: id,
    };
  }
}
