import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { arrayOf, normalize } from 'normalizr';
import { AbsenteeOptionSchema } from '../../shared/schemas';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { assignSchemaEntity } from '../../shared/assign';
import { UnsafeAction as Action } from '../../interfaces';

@Injectable()
export class AbsenteeOptionApi {
  private endpoint: string = 'absentee_options/';

  constructor(private gateway: ApiGateway) {}

  load(): Observable<any> {
    const params = { allow_deleted: '1', include: 'Group' };

    return this.gateway.get(this.endpoint, { params: params }).pipe(
      map((res) => reformatListResponse('AbsenteeOption', res)),
      map((data) => normalize(data, arrayOf(AbsenteeOptionSchema), { assignEntity: assignSchemaEntity }))
    );
  }

  add(data, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('AbsenteeOption', res)),
      map((data) => normalize(data, AbsenteeOptionSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  update(id, data, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + id, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('AbsenteeOption', res)),
      map((data) => normalize(data, AbsenteeOptionSchema, { assignEntity: assignSchemaEntity }))
    );
  }

  delete(id, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(this.endpoint + id, undefined, dispatchStart);
  }
}
