import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { createSelector } from 'reselect';
import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { AppState } from '../../index';
import { mapAndSortEntities } from '../../shared/entity.helper';
import mapValues from 'lodash-es/mapValues';
import sortBy from 'lodash-es/sortBy';
import { AbsenteeOptionModel, AbsenteeOptionState } from './absentee-option.model';
import { AbsenteeOptionAction } from './absentee-option.action';
import { AbsenteeOptionApi } from './absentee-option.api';
import { getPermissionState, hasPermission, PermissionCheck } from '../../auth/permission.helper';
import { isColorDark } from '../../../shared/contrast.helper';

const iconMap = {
  vacation_hours: 'absentee-vacation',
  sick_hours: 'absentee-sick',
  unavailable_hours: 'absentee-unavailable',
  holiday_hours: 'absentee-national_day',
  maternity_leave_hours: 'absentee_maternity_leave',
  special_leave_hours: 'absentee_other',
  short_term_care_leave_hours: 'absentee_other',
  long_term_care_leave_hours: 'absentee_other',
  parental_leave_hours: 'absentee_other',
  unpaid_leave_hours: 'absentee_other',
};

const colorMap = {
  vacation_hours: '#F2CA00',
  sick_hours: '#FF5555',
  unavailable_hours: '#333c4e',
  holiday_hours: '#AF2EFF',
  special_leave_hours: '#8cbb26',
  maternity_leave_hours: '#c4037d',
  short_term_care_leave_hours: '#444e99',
  long_term_care_leave_hours: '#ea621f',
  parental_leave_hours: '#fdc60b',
  unpaid_leave_hours: '#6d398b',
};

@Injectable()
export class AbsenteeOptionService {
  constructor(private store: Store<AppState>, private api: AbsenteeOptionApi) {}

  load() {
    this.store.dispatch(AbsenteeOptionAction.load());

    return this.api.load().pipe(
      map((response) => {
        this.store.dispatch(AbsenteeOptionAction.loadSuccess(response));
        return response;
      }),
      catchError((response) => {
        this.store.dispatch(AbsenteeOptionAction.loadFailed(response));
        return observableThrowError(response);
      })
    );
  }

  add(eventData): Observable<any> {
    return this.api.add(eventData, AbsenteeOptionAction.add(eventData)).pipe(
      tap((response) => {
        this.store.dispatch(AbsenteeOptionAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(AbsenteeOptionAction.addFailed(response));
        return observableThrowError(response);
      })
    );
  }

  update(id, eventData) {
    const action = AbsenteeOptionAction.update(eventData);

    return this.api.update(id, eventData, action).pipe(
      tap((response) => {
        this.store.dispatch(AbsenteeOptionAction.updateSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(AbsenteeOptionAction.updateFailed(response));
        return observableThrowError(response);
      })
    );
  }

  delete(id) {
    return this.api.delete(id, AbsenteeOptionAction.delete(id)).pipe(
      tap(() => {
        this.store.dispatch(AbsenteeOptionAction.deleteSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(AbsenteeOptionAction.deleteFailed(id));
        return observableThrowError(response);
      })
    );
  }
}

export const getPermittedAbsenteeOptions = (mustHaveId: string = null) =>
  createSelector(getPermissionState, getAllAbsenteeOptions, (permissionState, options) => {
    const check = {
      userId: 'me',
      departments: 'any',
    };

    return options
      .filter((option: AbsenteeOptionModel) => {
        const optionCheck: PermissionCheck = {
          ...check,
          permissions: ['Create absentee', option.permission],
        };

        return hasPermission(optionCheck, permissionState);
      })
      .filter((option: AbsenteeOptionModel) => {
        if (option.id === mustHaveId) return true;
        return !option.deleted;
      });
  });

export const sortAbsenteeOptions = (absenteeOptions: AbsenteeOptionModel[]): AbsenteeOptionModel[] =>
  sortBy(absenteeOptions, ['weight']);

export const mapAndSortAbsenteeOptions = mapAndSortEntities(sortAbsenteeOptions);

export const getAbsenteeOptionState = (appState: AppState): AbsenteeOptionState => appState.orm.absenteeOptions;

export const getAbsenteeOptionIds = compose((state) => state.items, getAbsenteeOptionState);
export const getAbsenteeOptionEntities = createSelector(getAbsenteeOptionState, (state) =>
  mapValues(state.itemsById, (entity) => {
    let icon;
    let color;

    if (!entity.hasOwnProperty('percentage')) {
      icon = iconMap[entity.option_key] ? iconMap[entity.option_key] : 'absentee-vacation';
      color = colorMap[entity.option_key] ? colorMap[entity.option_key] : '#F2CA00';
    } else {
      icon = entity.icon;
      color = entity.color;
    }

    const weight = parseInt(`${entity.weight}`, 10);

    const color_is_dark = isColorDark(color);
    const option: AbsenteeOptionModel = {
      ...entity,
      weight,
      color,
      icon,
      color_is_dark,
    };
    return option;
  })
);

export const getAbsenteeOptions = createSelector(
  getAbsenteeOptionIds,
  getAbsenteeOptionEntities,
  (absenteeOptionIds, absenteeOptionEntities) => {
    const entities = mapAndSortAbsenteeOptions(absenteeOptionIds, absenteeOptionEntities);
    return entities.filter((entity) => !entity.deleted);
  }
);

export const getAllAbsenteeOptions = createSelector(
  getAbsenteeOptionIds,
  getAbsenteeOptionEntities,
  mapAndSortAbsenteeOptions
);

export const getAbsenteeOptionById = (id: string) =>
  createSelector(getAbsenteeOptionEntities, (entities) => entities[id]);
